@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import url('https://fonts.cdnfonts.com/css/rounds-black');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    overscroll-behavior: none;
}

* {
    background-color: #ffffff00;
}
.wrapper {
    display: table;
    width: 100%;
}

.test {
    display: table;
    width: 100%;
    height: fit-content;
}

.mainpage {
    display: flex;
}

.textmainpage {
    display: flex;
    max-width: 850px;
    background-color: transparent;
}

.textmainpagesmall {
    display: flex;
    max-width: 400px;
    background-color: transparent;
}

.mainmonkey {
    display: flex;
    position: absolute;
    height: fit-content;
    width: fit-content;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.mainmonkey-about {
    display: flex;
    position: absolute;
    height: fit-content;
    width: fit-content;
    right: 0;
    bottom: 100px;
    background-color: transparent;
}

@media (max-width: 640px) {
    .mainmonkey {
        width: 250px;
    }
}

.textabout {
    display: flex;
    max-width: fit-content;
    background-color: transparent;
}

.partners {
    display: flex;
    margin-left: 30px;
    background-color: transparent;
    position: fixed;
    bottom: 0;
    left: 0;
}

.mt-screen {
    margin-top: 50vh;
}

.transparent-bg {
    background-color: rgba(255, 255, 255, 0); /* Полностью прозрачный фон */
}

.transparent-white-bg {
    background-color: rgba(255, 255, 255, 0.2); /* Полупрозрачный белый фон */
}

/* Изменение цвета стрелок в Ant Design Carousel */
.ant-carousel .slick-prev::after,
.ant-carousel .slick-next::after {
    color: red !important; /* Изменяет цвет стрелок */
}

/* Изменение цвета точек навигации */
.ant-carousel .slick-dots li button {
    background-color: red !important; /* Делаем точки красными */
}

/* Чтобы активная точка была тоже красной */
.ant-carousel .slick-dots li.slick-active button {
    background-color: darkred !important; /* Темно-красный цвет для активной точки */
}


